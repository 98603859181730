<template>
  <div class="divide-y divide-solid divide-grey-300">
    <CommonListLabel :icon="AuthorIcon" :label="title" class="pb-4" />
    <!--:link="'/' + $t('Routes.Theme') + '/' + articleType"-->
    <div v-for="(article, index) in articles" class="flex relative py-4">
      <NuxtLink
        :key="'li' + index"
        :to="{
          name: currentPaper ? 'paper_article' : 'article',
          params: currentPaper
            ? { paper: currentPaper, id: article.UrlKey }
            : { id: article.UrlKey },
        }"
        class="flex relative"
      >
        <CommonImageLoader
          v-if="article.Writers && article.Writers[0]"
          :height="64"
          :width="64"
          :alt="article.Writers[0].Name"
          class="w-16 h-16 rounded-full bg-black bg-opacity-10 mr-2 shrink-0 overflow-hidden"
          :src="
            article.Writers[0].Type === 'writer'
              ? 'https://' +
                config.public.site.legacyurl +
                '/images/writers/' +
                appendToFilename(article.Writers[0].Image, '-2')
              : article.Writers[0].MasterId && article.Writers[0].MasterId !== 0
              ? 'https://legacy.altinget.dk/images/person/' +
                article.Writers[0].MasterId +
                '/' +
                article.Writers[0].Image
              : 'https://' +
                config.public.site.legacyurl +
                '/images/person/' +
                article.Writers[0].RecordId +
                '/' +
                article.Writers[0].Image
          "
        />
        <div>
          <div
            v-if="article.Writers && article.Writers[0]"
            class="list-label opacity-35 mb-1"
          >
            {{ article.Writers[0].Name }}
          </div>
          <div
            v-else-if="article.CustomWriter"
            class="list-label opacity-35 mb-1"
          >
            {{ article.CustomWriter }}
          </div>
          <div class="list-title-xxxs">
            {{ article.HeadLine || article.Headline }}
          </div>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import AuthorIcon from '~/assets/icons/skribenter.svg?url'
const props = defineProps({
  count: {
    type: Number,
    default: 5,
  },
  articleType: {
    type: Number,
    default: 71,
  },
  paper: {
    type: Number,
    default: null,
  },
  title: {
    type: String,
    default: 'AnalysesFrom',
  },
})
const nuxtApp = useNuxtApp()
const indexStore = useIndexStore()
const config = useRuntimeConfig()
const { data: articles } = await useAsyncData(async () => {
  return await nuxtApp.$api.content.articles({
    paper:
      props.paper ||
      (indexStore.currentPaper ? indexStore.currentPaper.RecordId : 1),
    id: props.articleType,
    type: 'articletype',
    count: props.count,
  })
})
const currentPaper = computed(() => {
  if (config.public.site.identifier === 'mm_dk') {
    return
  }

  return indexStore.currentPaperSlug
})
</script>
